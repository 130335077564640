<template>
  <vc-calendar
      :from-page.sync="page"
      :masks="{weekdays: 'WWW'}"
      :attributes="attributes"
      :is-expanded="true"
      :min-date="minDate"
      :max-date="maxDate"
      @dayclick="$emit('dayclick', $event)"/>
</template>

<script>
import {DateTime} from "luxon";
import {attributesFromAvailabilities} from "@/utils/calendar-utils";
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    selectedDate: Object,
    minDate: Date,
    maxDate: Date
  },
  data() {
    return {
      month: DateTime.local().month,
      year: DateTime.local().year,
      availabilities: []
    }
  },
  computed: {
    ...mapGetters(['reservation']),
    isReservationEditable() {
      return !this.reservation?.number
    },
    page: {
      get: function () {
        return {month: this.month, year: this.year}
      },
      set({month, year}) {
        this.month = month
        this.year = year
      }
    },
    attributes() {
      const attributes = attributesFromAvailabilities(this.availabilities, this.minDate)
      attributes.push({
        highlight: true,
        dates: this.selectedDate?.toString()
      })
      return attributes
    }
  },
  watch: {
    page(newPage) {
      this.loadAvailabilities()
      this.$emit('pageUpdate', newPage)
    },
    selectedDate(newDate) {
      if (newDate) {
        this.month = newDate.month
        this.year = newDate.year
      }
    }
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),
    async loadAvailabilities() {
      this.availabilities = await this.$api.get(`/availabilities/${this.year}/${this.month}`, {
        params: {
          channel: process.env.VUE_APP_SPABOOT_CHANNEL_ID
        }
      })
          .then(response => response.data)
          .catch(err => this.handleError(err))
    }
  },
  mounted() {
    this.loadAvailabilities()
  }
}
</script>
