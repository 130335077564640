<template>
  <div>
    <div id="calendarRow" class="row" v-if="reservationConstraints">
      <div class="col-12" v-if="isReservationEditable">
        <label for="reservationCalendar">
          Wann soll Dein Erlebnis stattfinden?
        </label>
        <reservation-calendar
            id="reservationCalendar"
            :selected-date="reservation.startTime"
            :min-date="reservationConstraints.firstDateForConfirmedReservations.toJSDate()"
            :max-date="maxDate.toJSDate()"
            @pageUpdate="page = $event"
            @dayclick="dateSelected($event)"
            @clear="reservation.startTime = undefined"/>
      </div>
      <div class="col-12 mt-1" v-if="isReservationEditable">
        <reservation-calendar-legend/>
      </div>
      <div class="col-12" v-if="!isReservationEditable">
        <div class="h5">{{ reservation.startTime | date }}</div>
      </div>
    </div>
    <div>
      <div class="row"
           v-if="reservation.startTime">
        <reservation-slot-and-article-selector class="col-12 mt-3"
                                               :reservation="reservation"
                                               @article-and-start-time-selected="articleAndStartTimeSelected"
                                               @reset="resetReservation"/>
      </div>
      <div id="attendeeCard" class="mt-3 card" v-if="reservationDefined">
        <div class="card-header">
          <span v-if="!isFixedAttendees">Wie viele Personen sollen dabei sein?</span>
          <span v-else>Dein Erlebnis</span>
        </div>
        <div class="card-body">
          <table class="table table-sm">
            <tr>
              <th colspan="3" class="small text-right">aktueller Preis*</th>
            </tr>
            <tr>
              <td>
                {{ selectedArticle.product.name }}
                <span class="small muted" v-if="selectedArticle.product.category === 'RES'">
                <br/>
                {{ reservation.startTime | dateTime }}
              </span>
              </td>
              <td class="text-nowrap">{{ selectedArticle.product.attendees }} Pers.</td>
              <td>{{ selectedArticle.grossPrice | price }}</td>
            </tr>
            <tr :class="{'text-muted': additionalAttendees <= 0}" v-if="!isFixedAttendees">
              <td>Zusätzliche Personen</td>
              <td class="text-nowrap">{{ additionalAttendees }} Pers.</td>
              <td>{{ price - selectedArticle.grossPrice | price }}</td>
            </tr>
            <tr v-if="!isFixedAttendees">
              <th class="text-right">Gesamt:</th>
              <th class="text-nowrap">{{ reservation.attendees }} Pers.</th>
              <th>{{ price | price }}</th>
            </tr>
          </table>
        </div>
        <div class="form-group col-12 justify-content-end d-flex" v-if="!isFixedAttendees">
          <button class="btn btn-sm btn-danger" @click="addAttendees(-1)"
                  :disabled="!additionalAttendees">
            <font-awesome-icon icon="minus-circle" class="mr-2"/>
            weniger Personen
          </button>
          <button class="ml-2 btn btn-sm btn-success" @click="addAttendees(1)"
                  :disabled="reservation.attendees >= selectedArticle.product.maxAttendees">
            <font-awesome-icon icon="plus-circle" class="mr-2"/>
            mehr Personen
          </button>
        </div>
        <div
            v-if="!startTimeToEarlyForNonConfirmedReservations"
            class="text-right text-muted small pr-3 px-3">
          Bei späterer Buchung kann die Anzahl der Personen noch einmal geändert werden.
        </div>
        <div class="position-relative w-100 text-right px-3 pb-1">
          <div class="text-muted small link"
               @click="childrenInfo = !childrenInfo" v-on-clickaway="hideChildrenInfo">
            <font-awesome-icon icon="info-circle"/>
            Kinder und Mindestalter
          </div>
          <div class="position-absolute text-muted w-75 bg-white border border-secondary rounded p-1 shadow"
               style="font-size: small; z-index: 99; right: 0;"
               v-show="childrenInfo">
            Aus Sicherheitsgründen müssen alle Teilnehmer einer Saunafahrt mindestens 12 Jahre alt sein und
            schwimmen
            können. Bei einer "Sauna am Steg" gibt es kein Mindestalter.<br/>
            Allerdings müssen Eltern bei Teilnahme von Minderjährigen einen Haftungsausschluss unterzeichnen,
            da das Boot nicht kindersicher ist und eine erhöhte Verletzungsgefahr besteht.
          </div>
        </div>
      </div>
      <slot :reservation="reservation"
            :reservationDefined="reservationDefined"
            :expirationDate="expirationDate"
            :reservationConstraints="reservationConstraints"
            :startTimeToEarlyForNonConfirmedReservations="startTimeToEarlyForNonConfirmedReservations">
      </slot>
      <div v-if="reservationDefined" class="text-muted small">
        <hr/>
        * Bei einer Reservierung, die erst später bezahlt wird,
        kann es zwischenzeitlich zu Preisanpassungen kommen. Es gilt immer der Preis des Buchungstages.
      </div>
    </div>
  </div>
</template>

<script>
import ReservationCalendar from "@/components/reservation/ReservationCalendar";
import {DateTime} from "luxon";
import ReservationCalendarLegend from "@/components/reservation/ReservationCalendarLegend";
import ReservationSlotAndArticleSelector from "@/components/reservation/ReservationSlotAndArticleSelector";
import {mapMutations} from "vuex";

const initialReservation = {
  state: 'requested',
  startTime: null,
  duration: 3,
  attendees: -1,
  product: {
    id: undefined,
  },
  customer: {
    email: ''
  }
}

export default {
  components: {ReservationSlotAndArticleSelector, ReservationCalendarLegend, ReservationCalendar},
  props: {
    res: Object
  },
  data() {
    return {
      reservation: {...initialReservation},
      price: 0,
      reservationConstraints: null,
      expirationDate: null,
      availableHours: [],
      selectedArticle: undefined,
      page: {
        month: DateTime.now().month,
        year: DateTime.now().year
      },
      childrenInfo: false,
    }
  },
  computed: {
    maxDate() {
      return DateTime.now().plus({year: 1})
    },
    startTimeTooEarly() {
      return this.reservation.startTime && this.reservationConstraints
          && this.reservation.startTime < this.reservationConstraints.firstDateForConfirmedReservations
    },
    startTimeToEarlyForNonConfirmedReservations() {
      return this.reservation.startTime && this.reservationConstraints
          && this.reservationConstraints.firstDateForUnconfirmedReservations > this.reservation.startTime
    },
    reservationDefined() {
      return this.selectedArticle
          && this.reservation?.product
          && this.reservation.startTime?.hour > 0
          && this.reservation.duration > 0
    },
    additionalAttendees() {
      let a = this.reservation.attendees
      if (this.selectedArticle)
        a -= this.selectedArticle.product.attendees
      return a
    },
    isFixedAttendees() {
      return this.selectedArticle?.product.attendees === this.selectedArticle?.product.minAttendees
          && this.selectedArticle?.product.attendees === this.selectedArticle?.product.maxAttendees
    },
    isReservationEditable() {
      return !this.reservation?.number
    }
  },
  watch: {
    res: {
      handler: function (res) {
        if (res) {
          this.reservation = {...res}
          this.reservation.prodcut = res.article?.product
        }
      },
      immediate: true
    },
    page() {
      this.resetReservation();
    },
    reservationDefined(newVal) {
      if (newVal) {
        this.updatePrice()
        this.$nextTick(() => this.$scrollTo('#attendeeCard'))
      }
    },
    'reservation.startTime': {
      handler: function (startTime) {
        if (!startTime) {
          this.expirationDate = null
        } else {
          this.$api.get('/reservation-expiration-date', {
            params: {
              y: startTime.year,
              m: startTime.month,
              d: startTime.day
            }
          }).then(response => {
            this.expirationDate = response.data
          }).catch(error => this.handleError(error))
        }
      }
    }
  },
  methods: {
    ...mapMutations(['reset']),
    dateSelected({day, month, year}) {
      const selectedDate = DateTime.local(year, month, day, 23, 59)
      if (selectedDate.toMillis() > this.reservationConstraints.firstDateForConfirmedReservations.toMillis()) {
        this.reservation.startTime = selectedDate
        this.reservationUpdated()
        this.$scrollTo('#calendarRow')
      }
    },
    articleAndStartTimeSelected({article, startTime}) {
      if (!article) {
        this.reservation.startTime = null
        this.reservation.product = {id: -1}
        this.reservation.attendees = 4
      } else {
        this.reservation.duration = article.product.duration
        if (!this.reservation.attendees
            || this.reservation.attendees > article.product.maxAttendees
            || this.reservation.attendees < article.product.attendees)
          this.reservation.attendees = article.product.attendees
        this.reservation.product = article.product
        this.reservation.startTime = startTime
        this.selectedArticle = article
      }
      this.reservationUpdated()
    },
    addAttendees(add) {
      const newAttendees = this.reservation.attendees + add
      if (newAttendees > this.selectedArticle.product.maxAttendees)
        this.showWarning('Die Anzahl der Teilnehmer bei ' + this.selectedArticle.product.name + ' ist auf ' + this.selectedArticle.product.maxAttendees + ' beschränkt.')
      else if (newAttendees < this.selectedArticle.product.minAttendees)
        this.showWarning('not enough')
      else {
        this.reservation.attendees = Math.max(this.selectedArticle.product.attendees, (Math.min(10, this.reservation.attendees + add)))
        this.reservationUpdated()
      }
    },
    reservationUpdated() {
      this.$emit('update', this.reservation)
      this.updatePrice()
    },
    resetReservation() {
      this.reset()
      this.reservation = {...initialReservation}
      this.selectedArticle = null
    },
    hideChildrenInfo() {
      this.childrenInfo = false
    },
    updatePrice() {
      if (this.reservationDefined) {
        const params = {
          product: this.reservation.product.id,
          attendees: this.reservation.attendees,
          startDate: this.reservation.startTime.toISODate()
        }
        this.$api.get('/prices', {params})
            .then(response => {
              this.price = response.data.amount
            }).catch(error => {
          this.handleError(error)
        })
      }
    },
  },
  mounted() {
    this.$api.get('/reservation-constraints')
        .then(response => {
          this.reservationConstraints = {
            firstDateForConfirmedReservations: DateTime.fromISO(response.data.firstDateForConfirmedReservations),
            firstDateForUnconfirmedReservations: DateTime.fromISO(response.data.firstDateForUnconfirmedReservations)
          }
        }).catch(error => this.handleError(error))
  }
}
</script>
