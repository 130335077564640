<template>
    <standard-layout>
        <template v-slot:heading>
            <h1>Sichere Dir Dein Erlebnis</h1>
        </template>
        <template v-slot:main>
            <div class="d-flex border border-success bg-white p-2 rounded"
                 v-if="allowNumberInput">
                <div class="w-100">
                    <span class="text-muted">Du hast bereits eine Reservierung?</span>
                </div>
                <div class="text-right text-nowrap">
                    <div class="btn btn-sm btn-success text-right" @click="reservationNumberInputModal = true">
                        Reservierungsnummer eingeben
                    </div>
                </div>
            </div>
            <reservation-editor
                    @update="reservation = $event"
                    class="mt-4"
                    :res="reservation">
                <template
                        v-slot:default="{reservation, reservationDefined, startTimeToEarlyForNonConfirmedReservations, expirationDate, reservationConstraints}">
                    <div class="row mt-5 text-center" v-if="reservationDefined">
                        <div class="col-5">
                            <div v-if="!reservationAlreadyPersisted">
                                <div class="text-center">
                                    <button class="btn btn-secondary"
                                            @click="confirm"
                                            :disabled="startTimeToEarlyForNonConfirmedReservations">
                                        nur Reservieren
                                    </button>
                                </div>
                                <div v-if="!startTimeToEarlyForNonConfirmedReservations"
                                     class="small text-secondary text-center mt-1">
                                    Das SPABoot wird für Dich reserviert.<br/>
                                    Die Reservierung verfällt, wenn Du nicht bis zum {{ expirationDate|date }} bezahlst.
                                </div>
                                <div v-else
                                     class="small text-warning text-center mt-1">
                                    An Deinem gewählten Datum kannst Du nur fest buchen.<br/>
                                    Für eine Reservierung musst Du ein Datum ab dem
                                    {{ reservationConstraints.firstDateForUnconfirmedReservations | date }}
                                    wählen.
                                </div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="text-center">
                                <button class="btn btn-primary w-100"
                                        @click="book">
                                    zur Buchung
                                </button>
                            </div>
                            <div class="small text-secondary text-center mt-1">
                                Hier kannst Du das SPABoot fest buchen.
                            </div>
                        </div>
                    </div>
                </template>
            </reservation-editor>
            <div v-if="reservationNumberInputModal">
                <modal title="Reservierung laden"
                       @close="reservationNumberInputModal = false">
                    <template v-slot:body>
                        <div class="form-group">
                            <label for="reservationNumberInput">Reservierungsnummer</label>
                            <input ref="reservationNumberInput"
                                   id="reservationNumberInput"
                                   class="form-control form-inline"
                                   type="text"
                                   v-model="reservationNumberInput"
                                   @keypress.enter="loadReservationFromNumberInput"/>
                            <small>Die Reservierungsnummer ist eine <span
                                    :class="{'text-danger':!reservationNumberInputValid}">12-stellige</span>
                                Kombination aus Zahlen und Buchstaben.
                                Du findest sie in der Bestätigungs-Email zu Deiner Reservierung.
                            </small>
                        </div>
                    </template>
                    <template v-slot:footer>
                        <button class="btn btn-primary"
                                :disabled="!reservationNumberInputValid"
                                @click="loadReservationFromNumberInput">
                            OK
                        </button>
                    </template>
                </modal>
            </div>
        </template>
    </standard-layout>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ReservationEditor from "@/components/reservation/ReservationEditor";
import Modal from "@/components/generic/Modal";

export default {
    metaInfo: {
        title: 'Reservieren',
        meta: [{
            name: 'description',
            content: 'Sichere Dir jetzt Dein Saunaboot-Erlebnis.'
        }]
    },
    components: {Modal, ReservationEditor},
    data() {
        return {
            reservation: {
                state: null
            },
            loading: false,
            loadingMessage: '',
            reservationNumberInputModal: false,
            reservationNumberInput: '',
            allowNumberInput: false
        }
    },
    computed: {
        ...mapGetters(['cart']),
        reservationNumberInputValid() {
            return this.reservationNumberInput?.length === 12
        },
        reservationAlreadyPersisted() {
            return this.reservation.number
        }
    },
    methods: {
        ...mapMutations(['updateReservation', 'reset']),
        ...mapActions(['loadReservation', 'initCartWithReservation', 'resetCart', 'startLoading', 'stopLoading', 'changeReservation']),
        confirm() {
            this.updateReservation(this.reservation)
            this.$router.push({name: 'confirmReservation'}).catch((error) => this.handleError(error))
        },
        async book() {
            this.startLoading('Erstelle Buchung...')
            this.updateReservation(this.reservation)
            if (this.reservation.number) {
                await this.changeReservation()
            }
            this.initCartWithReservation(this.reservation)
                .then(() => {
                    if (this.reservation?.product?.name?.includes('omantic'))
                        // HACK for Romantic Hideaway
                        this.$router.push({name: 'confirmBooking', params: {cart: this.$store.getters.cart.id}})
                    else
                        this.$router.push({name: 'addProducts', query: {'cart': this.$store.getters.cart.id}})
                }).catch(error => this.handleError(error))
                .finally(() => this.stopLoading())
        },
        loadReservationFromNumberInput() {
            this.$router.push({name: 'viewReservation', params: {number: this.reservationNumberInput}})
                .then(() => this.reservationNumberInputModal = false)
        }
    },
    async activated() {
        console.log('activated')
        const storedReservation = this.$store.getters.reservation;
        const storedCart = this.$store.getters.cart;
        console.log(storedReservation)
        console.log(storedCart)
        if (storedCart && storedCart.voucherCart)
            this.resetCart().catch(error => this.handleError(error))
        if (this.$route.params.number)
            await this.loadReservation(this.$route.params.number).catch(error => this.handleError(error))
        if (storedReservation && storedReservation.state !== 'expired' && storedReservation.state !== 'canceled')
            this.reservation = this.$store.getters.reservation
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.$gtag.event('reservation_interest', {})
        })
    }
}
</script>
