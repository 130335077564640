<template>
  <div class="d-flex w-100 justify-content-center flex-wrap">
    <div class="entry" v-for="entry in entries" :key="entry.name">
      <span class="entryColor" :class="entry.name"></span>&nbsp;
      <span>{{entry.displayName}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entries: [
        {name: 'available', displayName: 'verfügbar'},
        {name: 'constrained', displayName: 'teilweise gebucht'},
        {name: 'blocked', displayName: 'ausgebucht'},
        {name: 'selected', displayName: 'gewählt'}]
    }
  }
}
</script>
<style lang="scss" scoped>

.entry {
  display: flex;
  height: 100%;
  font-size: small;
  margin-left: 0.5rem;
  white-space: nowrap;
}

.entryColor {
  min-width: 1.2rem;
  min-height: 1.2rem;
  max-width: 1.2rem;
  max-height: 1.2rem;
  border-radius: 100%;
}

</style>
