<template>
  <div>
    <h5 v-if="isReservationEditable">Wähle den Zeitraum Deines Erlebnis<br/><small>{{reservation.startTime | date}}</small></h5>
    <h5 v-else>Dein gewähltes Erlebnis und Startzeit</h5>
    <div class="row border border-muted bg-white py-2 px-1"
         v-for="slotInfo in sortedSlotInfos"
         :key="'slotInfo_' + slotInfo.article.product.id">
      <div class="col-4 col-md-2 mt-1 align-self-center pr-0 img-fluid">
        <div class="text-center overflow-hidden">
          <cld-image :public-id="slotInfo.article.product.imageId"
                     height="70"
                     crop="fit"/>
        </div>
        <div class="text-center text-small">
          <b>{{ slotInfo.article.product.duration }} Stunden</b>
          ab {{ slotInfo.article.grossPrice |price }}
        </div>
      </div>
      <div class="col-8 col-md-10">
        <div class="small font-weight-bolder text-nowrap overflow-hidden d-flex">
          <div style="white-space: normal;">
            {{ slotInfo.article.product.name }}
            <router-link
                :to="{name: 'productDetail', params: {productId: slotInfo.article.product.id, returnRouteName: $route.name}}">
              <font-awesome-icon class="text-muted" icon="info-circle"/>
            </router-link>
          </div>
          <div class="text-right w-100"
               v-if="false && !isReservationEditable">
            <!-- ausgeschaltet. Problematik: es gibt zu viele reservation Objekte in den verschiedenen Komponenten,
            Wenn man navigiert, wird nicht alles upgedated.
            Architektur der Model Objekte ändern: reservation nur aus vux holen
            -->
            <router-link :to="{name: 'editReservation'}">reload</router-link>
            <span class="link text-danger font-weight-bold" @click="$emit('reset')">&times;</span>
          </div>
        </div>
        <div v-if="slotInfo.availableHours.length > 0 || !isReservationEditable">
          <div class="small" v-if="isReservationEditable">verfügbare Startzeiten</div>
          <div class="small" v-else>gebuchte Zeit</div>
          <button class="btn btn-sm m-1"
                  v-for="hour in hours"
                  :class="buttonClass(slotInfo, hour)"
                  :key="slotInfo.article.id + '_' + hour"
                  @click="slotSelected(slotInfo, hour)"
                  :disabled="slotInfo.availableHours.indexOf(hour) < 0 || !isReservationEditable">
            {{ hour }}:00 - {{hour + slotInfo.article.product.duration}}:00
          </button>
        </div>
        <div class="text-danger text-small" v-else>
          an diesem Tag nicht mehr verfügbar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateTime} from "luxon";

export default {
  props: {
    reservation: Object
  },
  data() {
    return {
      slotInfos: [],
      selectedSlotInfo: null,
      selectedHour: 0
    }
  },
  computed: {
    hours() {
      return [10, 11, 12, 13, 14, 15, 16, 17, 18]
    },
    displayedSlotInfos() {
      if (this.isReservationEditable || !this.selectedSlotInfo)
        return this.slotInfos
      return this.slotInfos.filter(si => si.article.id === this.selectedSlotInfo.article.id)
    },
    tripSlotInfos() {
      return this.displayedSlotInfos.filter(si => si.article.product.trip)
    },
    nonTripSlotInfos() {
      return this.displayedSlotInfos.filter(si => !si.article.product.trip)
    },
    sortedSlotInfos() {
      return [...this.tripSlotInfos, ...this.nonTripSlotInfos]
    },
    isReservationEditable() {
      return !this.reservation?.number
    }
  },
  watch: {
    reservation: {
      handler(newReservation) {
        if (newReservation?.startTime !== undefined) {
          this.selectedHour = newReservation.startTime.hour
          const res = {...newReservation}
          this.loadSlotInfos().then(sis => {
            this.slotInfos = sis
            // find slot for selectedArticle prop
            if (res.product) {
              let slotIndex = sis.findIndex(si => si.article.product.id === res.product.id)
              let productIdToSelect = slotIndex >= 0 && sis[slotIndex].article.product.id
              if (productIdToSelect && !this.selectedSlotInfo) {
                this.selectedSlotInfo = sis[slotIndex]
                this.selectedHour = res.startTime.hour
                this.$emit('article-and-start-time-selected',
                    {
                      article: this.selectedSlotInfo.article,
                      startTime: DateTime.fromObject({
                        year: res.startTime.year,
                        month: res.startTime.month,
                        day: res.startTime.day,
                        hour: res.startTime.hour
                      })
                    })
              }
            }
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    async loadSlotInfos() {
      return this.$api.get(`/availabilities/${this.reservation.startTime.year}/${this.reservation.startTime.month}/${this.reservation.startTime.day}`,
          {
            params: {
              channel: 'WEB',
            }
          })
          .then(res => res.data)
          .catch(err => this.handleError(err))
    },
    slotSelected(slotInfo, hour) {
      this.selectedSlotInfo = slotInfo
      this.selectedHour = hour
      if (this.selectedSlotInfo) {
        const res = {...this.reservation}
        this.$emit('article-and-start-time-selected',
            {
              article: slotInfo.article,
              startTime: DateTime.fromObject({
                year: res.startTime.year,
                month: res.startTime.month,
                day: res.startTime.day,
                hour: hour
              })
            })
      }
    },
    buttonClass(slotInfo, hour) {
      if (slotInfo.article.id === this.selectedSlotInfo?.article.id && hour === this.selectedHour)
        return 'btn-success'
      if (!this.isReservationEditable)
        return 'd-none'
      if (slotInfo.availableHours.indexOf(hour) >= 0)
        return 'btn-secondary'
      return 'd-none'
    }
  }
}
</script>

<style scoped lang="scss">
.text-small {
  font-size: small;
}
</style>
