export const attributesFromAvailabilities = function (availabilites, minDate) {
    return availabilites.map(a => {
        const attr = {...a}
        attr.dates = a.date
        if (new Date(a.date) >= minDate) {
            let _class = 'bg-success'
            if (a.state === 'constrained')
                _class = 'bg-warning'
            if (a.state === 'closed')
                _class = 'bg-danger'
            attr.highlight = {
                class: _class,
                fillMode: 'solid'
            }
        }
        return attr
    })
}
